/* Target devices with a max width of 768px (typical breakpoint for tablets and smaller) */
@media only screen and (max-width: 768px) {
    /* Styles for mobile devices */

  
    .container-verify {
      width: calc(100vw - 32px);
    }
  
  }
  